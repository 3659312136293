import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/MDXLayout.tsx";
import SEO from 'components/SEO';
import CauseHero from 'components/CauseHero';
import { graphql } from 'gatsby';
import { ContainerImageWide } from 'components/ContainerImageWide';
import { FoodAidContainer } from 'components/FoodAidContainer';
import { VideoContainer } from 'components/VideoContainer';
import { Embed } from 'theme-ui';
export const pageQuery = graphql`
  query AtlasReliefFR {
    heroImage: file(relativePath: {eq: "project-atlas-relief-hero.png"}) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    atlasFamily: file(relativePath: {eq: "atlas-family.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    atlasTeam: file(relativePath: {eq: "atlas-team.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="ATLAS Relief:" description="In the Atlas Mountains in Morocco, every winter that comes is a new challenge for the population: the challenge of survival, in the most difficult weather conditions. The freezing cold and the heavy snowfall aggravate their suffering." mdxType="SEO" />
    <CauseHero purpose="atlas-relief" title="ATLAS Relief" text="Aujourd'hui, des êtres humains meurent encore à cause du froid et de l'isolement." data={props.data} pagePath={props.path} mdxType="CauseHero" />
    <div className="p-wrapper">
      <p>{`Dans les montagnes de l’Atlas au Maroc, chaque hiver qui arrive est un nouveau défi pour les populations: celui de la survie dans les conditions climatiques les plus difficiles. Le froid glacial et les fortes chutes de neiges aggravent les souffrances de ces populations. Des milliers de villages se retrouvent coupés du monde, avec quasiment aucun moyen de ravitaillement durant les long mois d’hiver. Les enfants, les personnes malades et les femmes enceintes n’ont aucun accès aux soins médicaux de base.`}</p>
      <p>{`Chaque hiver, `}<strong>{`UMMATY`}</strong>{` organise la campagne `}<strong>{`ATLAS RELIEF`}</strong>{`. Elle consiste en plusieurs missions humanitaires durant lesquelles nos bénévoles portent assistance à ces personnes. Des `}<strong>{`dizaines de tonnes de nourriture sont distribuées ainsi que des vêtements chauds et des couvertures`}</strong>{`, afin de lutter contre le froid. Vos dons et votre soutien permettent la survie de ces villageois.`}</p>
    </div>
    <div className="p-wrapper">
  <VideoContainer width={['100%', '100%', '50%']} mdxType="VideoContainer">
    <Embed src="https://www.youtube.com/embed/t6nApSnEgXM" mdxType="Embed"></Embed>
  </VideoContainer>
    </div>
    <ContainerImageWide zIndex={-1} height={[200, 300, 400]} fluid={props.data.atlasFamily.childImageSharp.fluid} mdxType="ContainerImageWide"></ContainerImageWide>
    <div className="p-wrapper">
      <p>{`Avant chaque mission, nos équipes de bénévoles, en collaboration avec nos partenaires locaux ciblent une région en particulier. Dans les mois et les semaines précédant chaque opération, nous procédons à un recensement détaillé des familles habitants les villages ciblés, afin de nous assurer que vos aides bénéficient réellement aux plus nécessiteux. Non seulement nous sélectionnons les familles prioritaires, mais nous évaluons également leurs besoins particuliers afin que les aides distribuées soient en adéquation avec le nombre de membres, adultes et enfants qui les composent.`}</p>
      <p>{`Les bénévoles de UMMATY procèdent ensuite à la négociation et à l'achat des produits destinés à la distribution. Grâce aux nombreuses années d'expérience, UMMATY à pu tisser un réseau de fournisseurs garantissant la meilleure qualité, mais également les meilleurs prix du marché. Parmi les valeurs de UMMATY, il y a l’Excellence, le fait de toujours rechercher le meilleur, dans nos services aux donateurs, mais également et surtout dans l’aide apportée aux bénéficiaires. C’est pourquoi nous sommes fiers de pouvoir distribuer la meilleure qualité de denrées alimentaires dans des quantités bien au-delà de la moyenne des paniers alimentaires que peuvent distribuer d’autres organismes.`}</p>
      <p>{`Le panier alimentaire de UMMATY est plus qu’un simple panier ou une aide symbolique: `}<strong>{`chaque famille`}</strong>{` bénéficie de `}<strong>{`plus de 100KG de nourriture variée et 3 couvertures de bonne qualité`}</strong>{` qui permettent de les soutenir durant ces long mois d’hiver.`}</p>
    </div>
    <div className="p-wrapper">
  <FoodAidContainer content={[{
        title: 'Panier Alimentaire complet:',
        description: 'Farine blé tendre 50Kg, Blé dur 15Kg, Orge 10Kg, Huile 5L, Sucre 5kg, Lentilles 5Kg, Riz 5Kg, Pois chiches 2Kg, Haricots 5Kg, Lait en poudre 1.25Kg, Confiture 1Kg, Thé 3 unités',
        price: '70$ US',
        color: 'primary',
        backgroudColor: '#D3D3D4'
      }, {
        title: '3 Couvertures',
        description: '',
        price: '30$ US',
        color: 'primary',
        backgroudColor: '#F4F7F8'
      }, {
        title: "Parrainage d'une famille:",
        description: '(incluant 1 Panier alimentaire complet + 3 couvertures)',
        price: '100$ US',
        color: 'white',
        backgroudColor: '#055064'
      }]} mdxType="FoodAidContainer"></FoodAidContainer>
    </div>
    <div className="p-wrapper">
      <p>{`Alors que nous sommes au chaud cet hiver, des enfants et des personnes âgées continuent de souffrir de ces conditions extrêmement difficiles. Soyons là pour eux. Un simple don en ligne permet de changer leur quotidien. Vous pouvez faire un don, quel qu’en soit le montant, ou aussi choisir de `}<strong>{`parrainer une ou plusieurs familles`}</strong>{` afin de les aider à traverser cet hiver.`}</p>
    </div>
    <ContainerImageWide zIndex={-1} height={[200, 300, 400]} fluid={props.data.atlasTeam.childImageSharp.fluid} mdxType="ContainerImageWide"></ContainerImageWide>
    <div className="p-wrapper">
      <p>{`Le `}<strong>{`Prophète`}</strong>{` (que la prière d'Allah et Son salut soient sur lui) a dit: `}<strong>{`“Celui qui soulage un croyant d'un souci parmi les soucis de l'ici-bas Allah le soulage d'un souci parmi les soucis du jour du jugement”`}</strong>{`. Rapporté par Mouslim.`}</p>
      <p>{`Ensemble, avec UMMATY nous leur montrons que malgré leur isolement, ils ne sont pas seuls, qu’ils ne sont pas abandonnés à leur sort et que face à ces difficultés, `}<strong>{`votre générosité permet de déplacer des montagnes`}</strong>{`.`}</p>
    </div>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      